var typeText = document.querySelector(".type-text");

if (typeText == null) return;

var deTextToBeTypedArr = [
  "Ziel fürs nächste Tinder Date",
  "Challenge für die Boys",
  "gemeinsames Teambuilding",
  "Highlight für deinen Geburtstag",
  "Start in eure Girls Night",
  "Teamevent mit der Firma",
  "Ausflug mit der Familie",
];
var enTextToBeTypedArr = [
  "spot for your next Tinder date",
  "challenge for the boys",
  "teambuilding",
  "highlight for your birthday",
  "start into your girls night",
  "teamevent with your company",
  "trip with your family",
];

var language = typeText.dataset.language || "de";

var textToBeTypedArr =
  language === "en" ? enTextToBeTypedArr : deTextToBeTypedArr;

var index = 0,
  isAdding = true,
  textToBeTypedIndex = 0;

function playAnim() {
  setTimeout(
    function () {
      // set the text of typeText to a substring of the text to be typed using index.
      typeText.innerText = textToBeTypedArr[textToBeTypedIndex].slice(0, index);
      if (isAdding) {
        // adding text
        if (index > textToBeTypedArr[textToBeTypedIndex].length) {
          // no more text to add
          isAdding = false;
          typeText.classList.add("showAnim");
          //break: wait 2s before playing again
          setTimeout(function () {
            typeText.classList.remove("showAnim");
            playAnim();
          }, 1000);
          return;
        } else {
          // increment index by 1
          index++;
        }
      } else {
        // removing text
        if (index === 0) {
          // no more text to remove
          isAdding = true;
          //switch to next text in text array
          textToBeTypedIndex =
            (textToBeTypedIndex + 1) % textToBeTypedArr.length;
        } else {
          // decrement index by 1
          index--;
        }
      }
      // call itself
      playAnim();
    },
    isAdding ? 80 : 40
  );
}

// start animation
if (typeText != null) {
  playAnim();
}
