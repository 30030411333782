function addToggle(identifier) {
  const toggles = document.getElementsByClassName(identifier);
  if (toggles == null) return false;
  for (let i = 0; i < toggles.length; i++) {
    const toggle = toggles[i];
    const target = document.getElementById(
      toggle.getAttribute("data-target-id")
    );
    if (target == null) continue;
    toggle.addEventListener("click", function (event) {
      event.preventDefault();
      target.classList.toggle("hidden");
    });
  }
}
addToggle("toggle");
