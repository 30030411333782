window.toggleVisibility = function (event, elementId) {
  event.preventDefault(); // Prevent the default action (e.g., form submission, link navigation)

  var element = document.getElementById(elementId);
  if (element) {
    function toggle() {
      if (element.classList.contains("hidden")) {
        element.classList.remove("hidden");
      } else {
        element.classList.add("hidden");
      }
    }
    toggle();
  }
};

window.rotateArrow = function (elementId) {
  var arrowElement = document.getElementById(elementId);
  if (arrowElement) {
    if (arrowElement.classList.contains("rotate-90")) {
      arrowElement.classList.remove("rotate-90");
      arrowElement.classList.add("rotate-[270deg]");
    } else {
      arrowElement.classList.remove("rotate-[270deg]");
      arrowElement.classList.add("rotate-90");
    }
  }
};

window.handleToggle = function (event, articleId, arrowId) {
  toggleVisibility(event, articleId);
  rotateArrow(arrowId);
};

window.searchFaqs = function (event) {
  event.preventDefault();
  const searchElement = document.getElementById("search-input");
  const searchInput = searchElement.value;
  const searchContent = document.getElementById("search-content");
  const popupContent = document.getElementById("popup-content");
  const faqsAnswers = document.getElementById("faqs_answers");
  const customerRequestForm = document.getElementById("customer_request_form");
  const faqsMessage = document.getElementById("faqs_message");

  const locale = getLocale();
  const location = getLocation();

  fetch(`/faqs/search?search=${searchInput}&location=${location}`, {
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      Accept: "application/json",
    },
  })
    .then((response) => response.json())
    .then((data) => {
      searchContent.innerHTML = "";

      if (data.results.length > 0) {
        data.results.forEach((article) => {
          const resultDiv = document.createElement("div");
          resultDiv.className = "flex flex-col mb-4";
          resultDiv.innerHTML = `
          <div class="flex px-4 py-3 justify-between items-center cursor-pointer font-semibold">
            <div>${locale === "en" ? article.title_en : article.title_de}</div>
          </div>
          <div class="bg-white px-4 py-3 text-black">
            ${
              locale === "en"
                ? article.content_en.body
                : article.content_de.body
            }
          </div>

          <div class="divider"></div>
        `;
          popupContent.classList.add("hidden");
          searchContent.classList.remove("hidden");
          searchContent.appendChild(resultDiv);
        });
      } else {
        popupContent.classList.add("hidden");
        searchContent.classList.remove("hidden");
        const noResultMessage = searchElement.getAttribute(
          "data-no-result-message"
        );
        searchContent.innerHTML = `<div class="p-4">${noResultMessage}</div>`;
      }
    })
    .catch((error) => {
      console.error("Error fetching search results:", error);
    });
};

function getLocale() {
  const parsedUrl = new URL(document.URL);
  return parsedUrl.searchParams.get("locale") || "de"; // Default to 'de' if locale is not specified
}

function getLocation() {
  const parsedUrl = new URL(document.URL);
  const pathname = parsedUrl.pathname; // Get the pathname part of the URL
  const location = pathname.split('/').filter(part => part !== '')[0]; // Get the first part after splitting by '/'
  return location;
}

window.showInitialContent = function () {
  const searchInput = document.getElementById("search-input");
  const contentSection = document.getElementById("popup-content");
  const searchContent = document.getElementById("search-content");

  if (!searchInput.value.trim()) {
    searchContent.classList.add("hidden");
    contentSection.classList.remove("hidden");
  }
};
